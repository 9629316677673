<template>
  <div class="footer">
    <div class="container">
      <div class="logo-wrapper">
        <img class="logo" src="@/img/logo_footer.png">
      </div>
      <div class="column">
        <div class="btn">
          <router-link to="/"><img src="@/img/home.png">首页</router-link>
        </div>
        <div class="btn">
          <a href="/#/aboutUs"><img src="@/img/about.png">关于我们</a>
        </div>
        <div class="btn">
          <a href="/#/disclaimer"><img src="@/img/disclaimer.png">免责申明</a>
        </div>
        <div class="btn">
          <a href="/#/gamerule"><img src="@/img/rules.png">玩法规则</a>
        </div>
      </div>
      <div class="column">
        <div class="btn">
          <img src="@/img/global.png"> 彩票官网
        </div>
        <div class="btn s">
          <a href="https://www.lottery.gov.cn/kj/kjlb.html?qxc" target="_blank" rel="noreferrer noopener">七星彩</a>
        </div>
        <div class="btn s">
          <a href="https://www.lottery.gov.cn/plwf/index.html" target="_blank" rel="noreferrer noopener">排列三</a>
        </div>
        <div class="btn s">
          <a href="https://www.lottery.gov.cn/plwf/index.html" target="_blank" rel="noreferrer noopener">排列五</a>
        </div>
        <div class="btn s">
          <a href="https://bet.hkjc.com/marksix/Results.aspx?lang=ch" target="_blank" rel="noreferrer noopener">香港六合彩</a>
        </div>
        <div class="btn s">
          <a href="https://www.uk-wl.co.uk/lottoresult/" target="_blank" rel="noreferrer noopener">英国天天彩</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 325px;
  background-image: linear-gradient(to bottom, #d7b695, #805d3f);
  display: flex;
  justify-content: center;
  padding-top: 100px;

}
.container{
  width: 1440px;
  margin:0 auto;
  display:flex;
}
.logo-wrapper {
  margin-left: 0;
  margin-right: 317px;
}

.logo {
  width: 378px;
  height: 80px;
}

.btn {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #ffe5c3;
  margin-bottom:18px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  a {
    color: #ffe6cc;
    display: flex;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
  }
}

.btn.s {
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 8px;
  margin-left: 42px;
  font-family: Arial, 'Microsoft YaHei', 'PingFang', sans-serif;
  a{
    color: #edc59b;
  }
}
.column{
  margin-right:100px;
  margin-top:-25px;
  img{margin-right:14px;width:25px;height:25px;}

  &:last-of-type .btn:first-of-type{
    margin-bottom:18px;
  }
}
</style>