<template>
  <div class="lotteryHomeCard">
    <div class="row">
      <div class="img-wrapper">
        <div class="responsive-img">
          <img class="icon" :src="require(`@/img/ballIcon/${ lotteryConfig[lotteryCode].imgUrl}`)">
        </div>
      </div>

      <div class="period-info">
        <div class="row">
          <div>第<span class="red"> {{currentperiod}} </span>期</div>
          <div>下期开奖 <span class="red">{{nextperiod}} </span></div>
        </div>


        <div class="draw_number row">
          <div class="drawballs" v-show="lotteryCode !='xglhc' ">
            <template v-if="lotteryCode !='lucky7daily'"><!--英國天天除外-->
                <div :class="`${lotteryCode} ball ball-${parseInt(item)} ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''} `" v-for="(item,index) in lastDraw" :key="index">
                <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(item)}}</span>
                <span v-else>{{item}}</span>
                </div>
            </template>
            <template v-else>
                <template v-if="lastDraw[0] == ''">
                    <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 7" :key="index+'_3'">
                        <span> ? </span>
                    </div>
                </template>
                <template v-else>
                    <div :class="`${lotteryCode} ball ball-${item}`" v-for="(item,index) in lastDraw.slice(0, 6)" :key="index">
                        <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                    </div>
                    <div class="add" v-if='lastDraw.length === 7'> + </div>
                    <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[6])}`"  v-if='lastDraw.length === 7'>{{lastDraw[6] | padLeft(2)}}</div>
                    <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 7- lastDraw.length" :key="index+'_2'">
                        <span> ? </span>
                    </div>
                </template>
            </template>
          </div>
          <!--- 六合系列 -->
          <div v-if="lastDraw.length > 0" class="drawballs" v-show="lotteryCode==='xglhc' ">
            <div :class="`${lotteryCode} ball ball-${lastDraw[index]}`" v-for="(item,index) in 6" :key="index">{{lastDraw[index] | padLeft(2)}}</div>
            <div class="add"> + </div>
            <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[6])}`">{{lastDraw[6] | padLeft(2)}}</div>
          </div>
          <!-- 5分彩 -->
        </div>
        <LhcTable v-if="lotteryCode=='xglhc'" :lastperiod="tableData" :lotteryCode="lotteryCode" />
        <DefaultTable v-else-if="lotteryCode=='qxc'" :lastperiod="tableData" :lotteryCode="lotteryCode" />

      </div>

    </div>

    <div class="row lotterycard-action">
      <div class="countdown row" :class="lotteryCode=='lucky7daily' ? 'flex0':''"  v-if="lotteryDrawTime[lotteryCode]>0">
        <div class="time_block" v-if="lotteryConfig[lotteryCode].lotteryType==1">

          {{lotteryDrawTime[lotteryCode] | reciprocalDay}}
          <p>天</p>
        </div>
        <div class="time_block" v-if="lotteryConfig[lotteryCode].lotteryType==1">
          {{lotteryDrawTime[lotteryCode] | reciprocalHour}}
          <p>时</p>
        </div>
        <div class="time_block">
          {{lotteryDrawTime[lotteryCode] | reciprocalMinute}}
          <p>分</p>
        </div>
        <div class="time_block">
          {{lotteryDrawTime[lotteryCode] | reciprocalSecond}}
          <p>秒</p>
        </div>
      </div>
     
      <div class="countdown"  style="align-items: center" v-else>
        <div class="icon"><img class="rotating" src="@/img/H5/return-1.png">开奖中...</div>
      </div>
      <!-- 圖紙圖庫按鈕-英國天天彩 -->
        <div class="imageLibBtn"  v-if="lotteryCode=='lucky7daily'" style="align-items: center">
          <a class="item" @click="()=>{this.$router.push({path:`/mobile/lottery/${lotteryCode}`,query:{currentTab:'imageLib'} })}">
              <img src="@/img/H5/imageLib-button.png">
          </a>
      </div>
      <a class="item active" :class="{active: isActive}" @click="`${isActive=!isActive}`" :href="`/#/mobile/lottery/${lotteryCode}`" ><img src="@/img/H5/statistics.png"/>开奖历史</a>
        <div @click="openLiveStream" class="VideoBtn">
            <a class="item" :class="lotteryConfig[lotteryCode].liveStream ? 'active': ''" style="pointer-events: none;">
                <img :src="require(lotteryConfig[lotteryCode].liveStream ? '@/img/H5/play-button.png' : '@/img/H5/play-button-close.png')"/>开奖视频
            </a>
        </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import DefaultTable from '@/components/H5/default_home_table.vue'
import LhcTable from '@/components/H5/lhc_home_table.vue'
import path from 'path';
export default {
  name: 'lotteryHomeCard',
  props: {
    lotteryCode: String,
  },
  components: {
        DefaultTable,
        LhcTable,
  },
  data() {
    return{
      nextperiod:{},
      currentperiod: {},
      lastDraw: [],
      lotteryConfig: lotteryConfig,
      tableData: [],
      isActive: false,
      padLeftLottery: ['azxy8','jisukuaile','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc'],
      LotteryTwenty:['cannon20','azxy20','cannon20ex'],
      padLeftLottery2: ['azxy10','azxy20','jisusaiche']
    }
  },
  computed: {
    ...mapState([
      "baseInfo",
      "lotteryDrawTime"
    ]),
  },
  watch: {
    baseInfo: {
      handler: function(value){

        this.setLotteryInfo(this.lotteryCode);
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    setLotteryInfo: function(lotteryCode) {

      if(!this.baseInfo[lotteryCode]) {
        return;
      }

      let lotteryInfo = this.baseInfo[lotteryCode];
      let lastperiodInfo = this.baseInfo[lotteryCode].last_period;
      this.lotteryName = lotteryInfo.name; // 樂透名
      if(lotteryInfo) {
        this.nextperiod = lotteryInfo.current_period.period_no;
        this.currentperiod = lotteryInfo.last_period.period_no;
        this.lastDraw = this.$options.filters.splitDrawNumber(lastperiodInfo.draw.split(","),this.lotteryCode);
        this.tableData = lastperiodInfo;
      }
    },
    openLiveStream: function(){
        if(!this.lotteryConfig[this.lotteryCode].liveStream ){
            return false;
        } else {
            this.$emit('openVedio', this.lotteryCode, this.baseInfo[this.lotteryCode]);
        }
    }
  },
}
</script>
<style scoped lang="scss" >

.row{display:flex;flex-direction:row;margin: 13px 8px;justify-content: space-between}
.ball{width:20px;height:20px;}
.red{color:#f00;}
.lotteryHomeCard{
  width: 100%;
  display: flex;
  flex-direction:column;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 0;
  border-radius:5px;
  box-shadow: 1px 1px 16px 1px rgba(0,0,0,0.15);
  overflow: hidden;
}
.lotterycard-action{
  background:#e9bf95;
  color:#5d2d00;
  margin:0;
}
.img-wrapper{
  width:70px;
  margin-right:10px;
  @media(min-width:768px){
    width:120px;
  }
}
.responsive-img{
  position:relative;
  height:0;
  padding-bottom:100%;
}
img.icon{
  position:absolute;
  width:100%;
  height:100%;
}
.period-info{
  display:flex;
  flex-direction:column;
  width:100%;
  color:#5d2d00;
  font-size:12px;
  .row{
    margin:0;
    flex:1;
    justify-content: center;
    &:first-of-type{flex-grow:0;}
    > div{
      width:50%;
    }
  }
  .draw_number{
    justify-content: flex-start;
    align-items:center;
    -webkit-align-items: center;
  }
  .drawballs{
    grid-gap:2px;
    margin-top:8px;
  }
}


.lotterycard-action{
  display:flex;
  font-size:12px;

  .countdown{
    display:flex;flex:4;justify-content: flex-start;
    margin:10px 0;
    &.flex0{
      flex: 0;
    }
    p{margin:4px 0;}
    .time_block{
      width:28px;
      height:28px;
      text-align:center;
      position:relative;
      &:after{
        position:absolute;
        width:4px;height:28px;
        content:':';
        right:0;
        top:-1px;
        display:block;
      }
      &:last-of-type:after{display:none;}
    }
  }
  .icon, .item{
    align-items: center;display:flex;
    display: -webkit-flex;
    -webkit-align-items: center;
    }
  .icon img{margin:0 5px;}
  img{margin:0 4px;}
  .item{
    padding:2px 0px;
    justify-content: center;
    flex:2;
    color: #CEA279;
    border-left:1px solid #edccaa;
    &.active{
        color: #5d2d00;;
    }
  }
  .imageLibBtn{
    display: flex;
    flex: 2;
    a{
      border:0;
    }
    img{
      margin: 0;
      width: 70px;
    }
  }
}

.ball-99{
    background: #505050
}

.VideoBtn{
    display: flex;
    flex: 2;
    .item{
        &.active{
            color: #5d2d00;;
        }
    }
    
}
</style>