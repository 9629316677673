<template>
  <div class="header_menu" v-if="isShow" @mouseleave="hideMenu">
    <div class="content">
      <div class="row">
        <div class="lottery_type">
          <div class="title">
            全国彩
          </div>
        </div>
        <div class="lottery_option">
          <a class="lottery_item" href="/#/lottery/qxc" @click="hideMenu">
            七星彩
          </a>
          <a class="lottery_item" href="/#/lottery/pl3" @click="hideMenu">
            排列三
          </a>
          <a class="lottery_item" href="/#/lottery/pl5" @click="hideMenu">
            排列五
          </a>
          <a class="lottery_item" href="/#/lottery/xglhc" @click="hideMenu">
            香港六合彩
          </a>
        </div>
      </div>
      <div class="row">
        <div class="lottery_type">
          <div class="title">
            境外彩
          </div>
        </div>
        <div class="lottery_option three-level">
          <div class="lottery_group">
            <h4>英国五分彩</h4>
            <div class="lottery-list">
              <a href="/#/lottery/penta5classic">幸运五星彩</a>
              <a href="/#/lottery/surfing10classic">英国飞艇</a>
            </div>
          </div>
          <div class="lottery_group">
            <h4>英国三分彩</h4>
            <div class="lottery-list">
              <a href="/#/lottery/rolling10">英國赛车</a>
              <a href="/#/lottery/penta5">英国时时彩</a>
              <a href="/#/lottery/infinity8">英国快乐彩</a>
              <a href="/#/lottery/cannon20">英国快乐8</a>
              <a href="/#/lottery/lucky5">英国11选5</a>
              <a href="/#/lottery/gamma3">英国快三</a>
            </div>
          </div>
          <div class="lottery_group">
            <h4>英国极速彩</h4>
            <div class="lottery-list">
              <a href="/#/lottery/rolling10ex">英国极速赛车</a>
              <a href="/#/lottery/penta5ex">英国极速时时彩</a>
              <a href="/#/lottery/infinity8ex">英国极速快乐彩</a>
              <a href="/#/lottery/cannon20ex">英国极速快乐8</a>
              <a href="/#/lottery/lucky5ex">英国极速11选5</a>
              <a href="/#/lottery/gamma3ex">英国极速快三</a>
            </div>
          </div>
          <div class="lottery_group">
            <h4>英国天天彩</h4>
            <div class="lottery-list">
              <a href="/#/lottery/lucky7daily">英国天天彩</a>
              <a href="/#/lottery/pl5daily">英国五球彩
              <img src="@/img/new_img.png" class="icon"></a>
            </div>
          </div>

          <div class="lottery_group">
            <h4>澳洲彩</h4>
            <div class="lottery-list">
              <a href="/#/lottery/azxy5">澳洲幸运5</a>
              <a href="/#/lottery/azxy8">澳洲幸运8</a>
              <a href="/#/lottery/azxy10">澳洲幸运10</a>
              <a href="/#/lottery/azxy20">澳洲幸运20</a>
            </div>
          </div>


        </div>
      </div>


      <div class="row">
        <div class="lottery_type">
          <div class="title" style="background:transparent;">

          </div>
        </div>
        <div class="lottery_option three-level">
          <div class="lottery_group">
            <h4>极速系列</h4>
            <div class="lottery-list">
              <a href="/#/lottery/jisukuai3">极速快三</a>
              <a href="/#/lottery/jisusaiche">极速赛车</a>
              <a href="/#/lottery/jisushishi">极速时时彩</a>
              <a href="/#/lottery/jisukuaile">极速快乐十分</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import ComingLottery from '@/components/common/ComingLottery.vue'

export default {
  name: 'HeaderMenu',
  props: {
    msg: String
  },
  data() {
    return {
      isShow: true,
      lotteryList: [],
    }
  },
  components: {},
  methods: {
    showMenu() {
      this.isShow = true;
    },
    hideMenu() {
      this.$emit("hideMenu");
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header_menu {
  position: absolute;
  z-index: 100;
  left:160px;
  font-family: Arial, 'Microsoft YaHei', 'PingFang', sans-serif;
  box-shadow: 0 1px 5px 1px #000;
  .content {
    width: 980px;
    position: relative;
    background-color: rgba(2, 18, 52, 0.9);
    padding: 10px 20px 10px 0;
  }
}
.row{
  display:flex;
  flex-direction: row;
  padding-top: 5px;
  &:first-of-type .lottery_option{
    padding-top:0;
  }
}
.lottery_type {
  .title {
    font-size: 20px;
    text-align: center;
    width:88px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #1e273f;
    padding:6px 10px;
    background: #ab8151;
    margin-right: 120px;
  }
}

.lottery_option {
  width:100%;
  border-bottom:1px solid #3f4c6d;
  padding-top:5px;

  .lottery_item:hover {
    color: #ffe6cc;
  }
  .lottery_group{
    display:flex;
    position:relative;
    border-bottom:1px solid #3f4c6d;
    margin-bottom: 8px;
    &:last-of-type{border-bottom: 1px solid transparent}
  }
}
a {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: left;
  color: #7fb2eb;
  margin-right: 20px;
  &:hover{color:#fff;}
}

h4{
  font-size:16px;
  color:#af8c6c;
  width:100px;
  text-align:right;
  position: absolute;
  left: -116px;
  top: 12px;
}
.icon{
  position: relative;
  top: -15px;
  left: -16px;
}
</style>