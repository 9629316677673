<template>
    <div>
        <div class="paginate_wrapper"  v-if="this.dailyInfo.includes(currentHistoryName)">
            <paginate
                ref="pager"
                :page-count="pageTotal"
                :click-handler="clickPageController"
                :prev-link-class="'prevLink'"
                :disabled-class="'disabled'"
                :active-class="'active'"
                :next-link-class="'nextLink'"
                :page-link-class="'link'"
                :page-class="'item'"
                prev-text=""
                next-text=""
                :container-class="'paginateStyle'">
            </paginate>
        </div>
        <Lhc v-if="currentHistoryName === 'xglhc' || currentHistoryName === 'sgplhc' || currentHistoryName === 'lucky7daily'" :dataList="dataList" :appendList="appendList" :name="currentHistoryName" :selectedDate="selectedDate" />

        <Qxc v-else-if="currentHistoryName === 'qxc'" :dataList="dataList" :appendList="appendList" :name="currentHistoryName" :selectedDate="selectedDate" />

        <Pl3 v-else-if="currentHistoryName === 'pl3'" :dataList="dataList" :appendList="appendList" :name="currentHistoryName"/>

        <Pl5 v-else-if="currentHistoryName === 'pl5'" :dataList="dataList" :appendList="appendList" :name="currentHistoryName"/>

        <Default v-else :dataList="dataList" :appendList="appendList" :name="currentHistoryName"/>

        <div class="loading" :class="loading===true?'fadein':''">
          <div v-if="pageTotal !== 0 && pageIndex === pageTotal">已是最后一页</div>
          <div v-else>载入中...</div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLotteryHistoryForDate, getLotteryHistoryStatistics, getLotteryHistoryStatisticsDaily } from '@/api/api';
import Lhc from "@/components/H5/lottery_result/history/Lhc";
import Qxc from "@/components/H5/lottery_result/history/Qxc";
import Pl3 from "@/components/H5/lottery_result/history/Pl3";
import Pl5 from "@/components/H5/lottery_result/history/Pl5";
import Default from "@/components/H5/lottery_result/history/Default";

export default {
    components:{
      Lhc,
      Qxc,
      Pl3,
      Pl5,
      Default
    },
  props:{
    date:'',
  },
    data(){
        return{
            currentHistoryName:'',
            dataList:[],
            appendList:[],
            pageIndex:1, // 當前頁數
            pageSize:20, // 每頁顯示幾筆
            records: 0,  // 總資料筆數
            pageTotal:0,  // 總頁數
            callHistory: false,
            observer:null,
            selectedDate:'',
            historyTable: null,
            lastRow:null,
            firstRender:0,
            loading:true,
            error: null,
            dailyInfo: ['lucky7daily', 'pl5daily', 'qxc', 'pl3', 'pl5', 'xglhc'],
        }
    },
     computed: {
        ...mapState([
            "lotteryDrawTime"
        ]),
    },
    watch: {
        '$route': {
            handler: function(to, from) {
              this.currentHistoryName = to.params.name;
              this.pageIndex = 1;
              this.getHistoryInfo();
              this.lotteryUtil.changeLottery();
              this.dataList = [];
              this.appendList = [];
              this.firstRender=0;
              this.loading=true
              let pager = this.$refs.pager;
              if(pager) {
                pager.innerValue = 1;
              }    
            },
            immediate: true
        } 
    },
    methods:{
        async getHistoryInfo(selectedDate = ''){
            this.selectedDate = selectedDate !=='' ? selectedDate : this.date;
            // 獲取彩種
            try{ 
              let result;
              if (this.dailyInfo.includes(this.currentHistoryName)){
                this.pageSize = 30;
                result = await getLotteryHistoryStatisticsDaily( this.currentHistoryName, this.pageIndex, this.selectedDate, this.pageSize);
              } else {
                result = await getLotteryHistoryStatistics( this.currentHistoryName, '', this.selectedDate);
              }
              this.dataList = result.data.statistics;
              this.pageTotal = Math.ceil(result.data.record_count / this.pageSize);
              if(this.pageTotal !== 0){
                setTimeout(()=>{
                  if (this.dataList.length > 0){
                    this.historyTable = document.querySelector('table');
                    this.lastRow = this.historyTable.rows[this.historyTable.rows.length-1];
                    this.firstRender++;

                    if (this.firstRender === 1 ){
                      this.observer.observe(this.lastRow);
                    }
                  }
                },500)
              }
              this.loading = false;
            }catch(e){
            }
        },
        hitBottom(entries){
          if (this.dailyInfo.includes(this.currentHistoryName)){
            return;
          }
          const [{ isIntersecting }] = entries
          if (isIntersecting){
            this.nextPage();
          }
        },
        async nextPage(){
          this.observer.unobserve(this.lastRow);
          if (this.pageIndex !== this.pageTotal){
            this.pageIndex++;
            this.loading=true;
            let result = await getLotteryHistoryStatistics(this.currentHistoryName, this.pageIndex, this.selectedDate);
            this.appendList.push(...result.data.statistics)
            this.observeBottom();
          }else{
            //this.observer.disconnect();
          }
        },
        observeBottom(){
          setTimeout(()=>{
            this.lastRow = this.historyTable.rows[this.historyTable.rows.length-1];
            this.observer.observe(this.lastRow);
            this.loading = false;
          },500)
        },
        clickPage(date){
          if (this.dailyInfo.includes(this.currentHistoryName)){
            let pager = this.$refs.pager;
            if(pager) {
              pager.innerValue = 1;
            }    
          } else {
            this.firstRender=0;
            this.appendList = [];
          }
          this.pageIndex = 1;
          this.getHistoryInfo(date);
        },
        clickPageController(pageIndex){
          this.pageIndex = pageIndex;
          this.getHistoryInfo();
        }
    },
    mounted(){
        this.currentHistoryName = this.$route.params.name;

        this.timer = setInterval(()=>{
            if(this.lotteryDrawTime[this.currentHistoryName] <= 0 ) {
                this.callHistory=true;
            }
            if(this.callHistory ) {
                this.callHistory=false;
                this.getHistoryInfo(this.selectedDate);
                 if(this.lotteryDrawTime[this.currentHistoryName] <= 0 ) {
                    this.lotteryUtil.changeLottery();
                }
            }
        },5000)
        this.observer = new IntersectionObserver(
            this.hitBottom,
            {
              root: null,
              rootMargin: '0px 0px -10px 0px',
              threshold: 1
            }
        );

    },
    beforeDestroy() {
      this.observer.disconnect();
    },
    destroyed() {
        clearInterval(this.timer);
    }
}
</script>
<style lang="scss" scoped>
.paginate_wrapper{
    display: flex;
    justify-content: flex-end;
    background-color:#fff;
}
.loading{
  border-radius:10px;
  padding:30px;
  position:fixed;
  top:45vh;
  left:50%;
  color:#fff;
  transform:translate(-50%, -50%);
  background:rgba(0,0,0,0.5);
  transition: opacity .5s;
  pointer-events: none;
  opacity:0;
}
.fadein{
  opacity:1;
}
</style>
