<template>
    <div class="lotteryInnerCard">
        <div class="lotteryInnerCard__drawNum">
            <!-- 以期號開獎--->
            <div class="period" v-if="periodType==1">
                <div class="item">第 <span class="text">{{periodNo}}</span> 期开奖号码</div>
                <div class="item-detail" v-if='lotteryType=="1"'>
                    <span v-if="lotteryCode==='xglhc'">每周二、四、六  21:30:00开奖 </span>
                    <span v-if="lotteryCode==='qxc'">每周二、五、日  21:30:00开奖 </span>
                    <span v-if="lotteryCode==='lucky7daily'">每日22:00:00开奖 </span>
                    <span v-if="lotteryCode==='pl3'">每日21:30:00开奖 </span>
                    <span v-if="lotteryCode==='pl5'">每日21:30:00开奖 </span>
                   
                </div>
                <div v-else class="item-detail">当前<span>{{drawCount}}</span>期,剩<span>{{totalCount-drawCount}}</span>期</div>

            </div>
            <!-- 以日期開獎--->
            <div class="period " v-if="periodType==2"><span class="text">{{lastDrawTime | formateDrawTimeDate}}</span> 开奖号码</div>

            
            <!-- 六合彩種--->
             <LhcBall 
                v-if="lotteryCode==='xglhc'"
                :lastDraw="lastDraw"  
                :lotteryCode="lotteryCode"
                :currentInfo="currentInfo" />
            
            <!-- 一般彩種--->
            
            <DefaultBall 
                v-else 
                :lastDraw="lastDraw" 
                :lotteryCode="lotteryCode" /> 
                
        </div>
        <div class="lotteryInnerCard__info">
            <div class="bottom" v-if="lotteryDrawTime[lotteryCode]>0">
                <div class="nextDraw">距离下期开奖: </div>
                <div class="time">
                    
                        <div class="item" v-if="lotteryConfig[lotteryCode].lotteryType==1">{{lotteryDrawTime[lotteryCode] | reciprocalDay}}</div><span class="text" v-if="lotteryConfig[lotteryCode].lotteryType==1" >天</span>
                        <div class="item" v-if="lotteryConfig[lotteryCode].lotteryType==1">{{lotteryDrawTime[lotteryCode] | reciprocalHour}}</div><span class="text" v-if="lotteryConfig[lotteryCode].lotteryType==1">时</span>
                    
                    <div class="item">{{lotteryDrawTime[lotteryCode] | reciprocalMinute}}</div><span class="text">分</span>
                    <div class="item">{{lotteryDrawTime[lotteryCode] | reciprocalSecond}}</div><span class="text">秒</span>
                </div>
            </div> 
            <div  class="bottom" v-else>
                <div class="countdown">
                    <div class="icon"><img class="rotating" src="@/img/H5/return-2.png">开奖中...</div>
                </div>
            </div>
            <div class="vedioBtn" @click="openLiveStream" v-if="lotteryConfig[lotteryCode].liveStream"></div>
        </div>
    </div>   
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import DefaultBall from "@/components/H5/lottery_inner_card/Default_Ball"
import LhcBall from "@/components/H5/lottery_inner_card/Lhc_Ball"
export default {
    components:{
        DefaultBall,
        LhcBall
    },
    props: {
        lotteryCode:{
            type: String,
            required: true
        }, 
        imgUrl:String,   
        lotteryType:String
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    watch: {
        lotteryDrawTime: {
            handler: function(value){
                let time = value[this.lotteryCode];
                if(time)
                this.timerCountDown = time;
            },
            immediate: true,
            deep: true,
        },
       baseInfo: {
            handler: function(value){
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        },
        lotteryCode: {
            handler: function(value){
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        },
    }, 
    data() {
        return {
           periodType:1,
           lotteryName:'',
           lastDraw:'',
           periodNo:'',
           currentInfo:'',
           drawCount: 0,
           totalCount: 0,
           lastDrawTime:'',// 給periodType:2 用
           lotteryConfig:lotteryConfig,
        }
    },
  methods: {
        setLotteryInfo: function(lotteryCode) {
            if(!this.baseInfo.last_period) {
                return;
            }
           
            // 如果匹配到彩種
            let lastperiodInfo = this.baseInfo.last_period;
           
            if(lastperiodInfo) {  
                this.lotteryName = this._lotteryConfig[lotteryCode].name;
                this.periodNo = lastperiodInfo.period_no;
                this.drawCount= lastperiodInfo.draw_count;
                this.totalCount= lastperiodInfo.total_count;
                this.lastDraw = lastperiodInfo.draw.length>0 ?  this.$options.filters.splitDrawNumber(lastperiodInfo.draw.split(","),lotteryCode) : []; 
                this.lastDrawTime = lastperiodInfo.draw_time; 
                this.currentInfo =  lastperiodInfo;      
            } 
           
            /*switch(this._lotteryConfig[lotteryCode].displayperiodType) {
                case "foreign":
                    this.periodType = 2;
                    break;
                default:
                    this.periodType = 1;
                    break;
            }*/
            
        },
        openLiveStream: function(){
            if(!this.lotteryConfig[this.lotteryCode].liveStream ){
                return false;
            } else {
                this.$emit('openVedio', this.lotteryCode, this.baseInfo);
            }
        }
    },
  created() {
    this.lotteryUtil.changeLottery();
  },
}
</script>


<style lang="scss" scoped>
img{
    width: 100%;
}
.lotteryInnerCard{
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    display: flex;
    flex-flow: column;
   
    &__info{
        .top{
            display: flex;
            justify-content: space-between;
            .name{
                font-size:12px;
                color: #0f2957;
                font-weight: 600;
            }
           
        }
        .bottom{
            width:90%;
            margin:10px ;
            margin-left: 37px;
            display: flex;
            flex-flow: row;
            align-items: center;
            display: -webkit-flex;
            -webkit-align-items: center;
            justify-content: center;

            .nextDraw{
                font-size: 15px;
                color: #626262;
                font-weight: 700;
                margin-right: 10px;
            }
            .time{
                color: #fff;
                font-family: "Jost";
                font-size: 15px;
                font-weight: 700;
                box-sizing: border-box;
                display: flex;
                color: #f31212;
                .item{
                   
                }
                span{
                    margin: 0  10px 0 .06rem;
                   
                    &:last-child{
                        margin-right: 0;
                    }
                }
                
            }
        }

        .icon{
            align-items: center;
            display: flex;
            display: -webkit-flex;
            -webkit-align-items: center;
            color:#ff0101;
            font-size: 15px;
             img{
                 width: 16px;
                 height: 16px;
                 margin: 0 5px;
             }
        }
        
    }
    &__drawNum{
        font-weight: 700;
        width: auto;
        max-width: 695px;
        .period{
            text-align: left;
            margin: 10px 0;
            display: flex;
            flex-flow: row;
            justify-content: space-evenly;
            align-items: center;
            .item{
                font-size: 15px;
                color: #626262;
            }
           
            .text{
                color: #6c3e0a;
            }
        }
         .item-detail{
                font-size: 12px;
                color: #999;
            }
    }     
}

.lotteryInnerCard__info{
    display: flex;
    justify-content: center;
    align-items: center;
    .vedioBtn{
        width: 11%;
        height: 35px;
        background: url('~@/img/H5/liveStream.png');        
        background-position: left;
        background-repeat: no-repeat;
    }
}
</style>