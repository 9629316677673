
<template>
    <div class="lotteryCard_type3"> 
        <div class="icon" v-if="imgUrl"><img :src="require('@/img/ballIcon/'+imgUrl)"></div> 
        <div class="info">
            <div class="flex">
                <span class="lottery_name">{{lotteryName}}</span> 
                <span  class="lottery_period">第<span class="date">{{lastPeriod}}</span> 期开奖号码</span>

                <div class="item-detail" v-if="lotteryConfig[lotteryCode].lotteryType==1">
                  <span v-if="lotteryCode==='xglhc'">每周二、四、六  21:30:00开奖 </span>
                  <span v-if="lotteryCode==='qxc'">每周二、五、日  21:30:00开奖 </span>
                  <span v-if="lotteryCode==='lucky7daily'">每日22:00:00开奖 </span>
                  <span v-if="lotteryCode==='pl3'">每日21:30:00开奖 </span>
                  <span v-if="lotteryCode==='pl5'">每日21:30:00开奖 </span>
                  <span v-if="lotteryCode==='pl5daily'">每日21:00:00开奖 </span>
                </div>
                <div v-else class="item-detail">当前<span> {{drawCount}} </span>期,剩<span> {{totalCount-drawCount}} </span>期</div>
            </div>

            <!--- 六合彩球號顯示  ---> 
            <LhcBall 
                :lastDraw="lastDraw" 
                :lotteryCode="lotteryCode" 
                :lastPeriod="lastPeriod"
                :lastperiodInfo="componentlastperiodInfo"
                v-if=" lotteryCode=='xglhc'"/>
            <!--- 一般彩種球號顯示  --->
            <DefaultBall 
                :lastDraw="lastDraw "
                :lotteryCode="lotteryCode" v-else/>
        </div>
        <!--直播圖片從time拉出來-->
        <div class="live_player">
            <!--沒直播-->
            <template v-if="!this.lotteryConfig[this.lotteryCode].liveStream">
                <div class="live_img"><img src="@/img/live_big_icon.png"></div>
                <div class="title">开奖直播</div>
            </template>
            <!--有直播-->
            <template v-else>
                <div class="live_img active" @click="openVedio"><img src="@/img/live_big_icon_open.png"></div>
                <div class="title"></div>
            </template>
        </div>
        <!--- 六合倒數 + Live  ---> 
        <LhcTime 
                :lotteryCode="lotteryCode" 
                v-if=" lotteryCode=='xglhc'"/>
        <!--- 一般彩種倒數 + Live  ---> 
        <DefaultTime 
                :lotteryCode="lotteryCode" 
                v-else/> 
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import LhcBall from "@/components/common/lotteryCard/lotteryCardType3/lhc_ball";
import DefaultBall from "@/components/common/lotteryCard/lotteryCardType3/default_ball";
import LhcTime from "@/components/common/lotteryCard/lotteryCardType3/lhc_time";
import DefaultTime from "@/components/common/lotteryCard/lotteryCardType3/default_time";

export default {
    name: 'LotteryCard_type3',
    components:{
        LhcBall,
        DefaultBall,
        LhcTime,
        DefaultTime
    },
    props: {
        lotteryCode:{
            type: String,
            required: true
        }, 
        imgUrl:String,
        lotteryName:String,
    },
    data() {
        return {
            timerCountDown: 9999,
            lastPeriod: "",
            lastDrawNumber:'',
            lastDraw: [],
            displayPeriodType:1,
            lastDrawTime: "",
            lotteryConfig: lotteryConfig,
            componentlastperiodInfo: null,
            drawCount:0,
            totalCount:0,
        }
    },
    computed: {
        ...mapState([
            "baseInfo",
            "lotteryDrawTime"
        ]),
    },
    watch: {
        lotteryDrawTime: {
            handler: function(value){
                let time = value[this.lotteryCode];
                if(time)
                this.timerCountDown = time;
            },
            immediate: true,
            deep: true,
        },
        baseInfo: {
            handler: function(value){
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        },
        lotteryCode: {
            handler: function(value){
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        setLotteryInfo: function(lotteryCode) {
           /* if(!this.baseInfo[lotteryCode]) {
                this.lastPeriod = '';
                this.lastDraw = [];
                this.lastDrawTime = '';
                return;
            }*/
            let lastperiodInfo = this.baseInfo.last_period;

          if(lastperiodInfo) {

                this.lastPeriod = lastperiodInfo.period_no;
                this.lastDraw = this.$options.filters.splitDrawNumber(lastperiodInfo.draw.split(","),this.lotteryCode);
                this.lastDrawNumber = this.lastDraw[this.lastDraw.length-1];
                this.lastDrawTime = lastperiodInfo.draw_time;   
                this.componentlastperiodInfo = lastperiodInfo;
            this.drawCount= lastperiodInfo.draw_count;
            this.totalCount=lastperiodInfo.total_count;
            } 
            
            switch(this.lotteryConfig[this.lotteryCode].displayperiodType) {
                case "foreign":
                    this.displayPeriodType = 2;
                    break;
                default:
                    this.displayPeriodType = 1;
                    break;
            }
        },
        openVedio: function(){
            this.$emit('openVedio', this.baseInfo);
        }
    }
}
</script>

<style scoped lang="scss">
.lotteryCard_type3{
    display: flex;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    margin: 60px auto 60px auto;
    width: 1440px;
    background: #fff;
    border-radius: 10px;
    padding: 50px 40px;
    box-sizing: border-box;
    box-shadow: 0 3px 8px 0 rgba(2, 18, 52, 0.2);
    .icon{
        width: 150px;
        height: 150px;
        margin-right: 40px;
        img{
            width: 100%;
        }
    }
  .flex{display:flex;align-items: flex-end}
    .info{
        color: #0f2957;
        // padding-top:.21rem ;
        .lottery_name{
            font-size: 22px;
            margin-right: 32px;
        }
        .lottery_period{
            font-size: 18px;
            .date{
                color: #c19971;
            }
        } 
    }
    .item-detail{
      font-size: 14px;
      color: #999;
      margin-left: 20px;
      letter-spacing: 1px;
      font-family: "Microsoft YaHei";
      font-weight: 500;

    }
}

.live_player{
    margin-top: 80px;
    margin-right: 40px;
    .live_img{
        width: 103px;
        height: 36px;
        margin: 0 auto;
        img{
            width: 100%;
        }
        &.active{
            cursor: pointer;
        }
    }
    .title{
        text-align: center;
        font-size: 14px;
        color: #aab2ba;
        letter-spacing: 3px;
        margin: 3px 0 32px 0;
    }
}


</style>