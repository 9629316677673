<template>
  <div class="header">
    <div class="content">
      <!-- <img class="menu" src="@/img/hamburger.svg"> -->
      <div class="option">
        <div class="itme" @mouseover="showMenu(0)">
          <router-link to="/">首页</router-link>
        </div>
        <div class="itme" @mouseover="showMenu(1)" @click="switchShowMenu(1)" :class="isShowMenu==1?'up':'down'">
          <span>彩票大厅<div class="menu_icon"></div></span>
        </div>
        <div class="itme" @mouseover="showMenu(2)" @click="switchShowMenu(2)" :class="isShowMenu==2?'up':'down'">
          <span>全国彩<div class="menu_icon"></div></span>
        </div>
        <div class="itme" @mouseover="showMenu(3)" @click="switchShowMenu(3)" :class="isShowMenu==3?'up':'down'">
          <span>境外彩<div class="menu_icon"></div></span>
        </div>
        <div class="itme" @mouseover="showMenu(4)" @click="switchShowMenu(4)" :class="isShowMenu==4?'up':'down'">
          <span>彩票官网<div class="menu_icon"></div></span>
        </div>
        <div class="itme" @mouseover="showMenu(5)" @click="switchShowMenu(5)" :class="isShowMenu==5?'up':'down'">
          <span>图纸图库<div class="menu_icon"></div></span>
        </div>
      </div>
      <HeaderMenu v-if="isShowMenu==1" @hideMenu="hideMenu"/>
      <HeaderMenuSmall style="left:458px" v-if="isShowMenu==2" :lotteryList="lotteryList1" @hideMenu="hideMenu"/>
      <HeaderMenuForeign style="left:230px" v-if="isShowMenu==3" @hideMenu="hideMenu"/>
      <HeaderMenuSmall style="left:727px" v-if="isShowMenu==4" :lotteryList="lotteryList3" @hideMenu="hideMenu"/>
      <HeaderMenuSmall style="left:873px" v-if="isShowMenu==5" :lotteryList="lotteryList4" @hideMenu="hideMenu"/>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/common/header/HeaderMenu.vue'
import HeaderMenuSmall from '@/components/common/header/HeaderMenuSmall.vue'
import HeaderMenuForeign from "@/components/common/header/HeaderMenuForeign";

export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
    return {
      isShow: false,
      isShowMenu: 0,
      lotteryList1: [
        {
          name: "七星彩",
          url: "/#/lottery/qxc",
        },
        {
          name: "排列三",
          url: "/#/lottery/pl3",
        },
        {
          name: "排列五",
          url: "/#/lottery/pl5",
        },
        {
          name: "香港六合彩",
          url: "/#/lottery/xglhc",
        },
      ],
      lotteryList3: [

        {
          name: "香港六合彩",
          url: "https://bet.hkjc.com/marksix/Results.aspx?lang=ch",
        },
        {
          name: "七星彩",
          url: "https://www.lottery.gov.cn/kj/kjlb.html?qxc",
        },
        {
          name: "排列三",
          url: "https://www.lottery.gov.cn/plwf/index.html",
        },
        {
          name: "排列五",
          url: "https://www.lottery.gov.cn/plwf/index.html",
        },
        {
          name: "英国天天彩",
          url: "https://www.uk-wl.co.uk/lottoresult/",
        }
      ],
      lotteryList4: [
        {
          name: "香港六合彩",
          url: "/#/imageslib/xglhc",
        },
        {
          name: "英国天天彩",
          url: "/#/imageslib/lucky7daily",
        }
      ]
    }
  },
  components: {
    HeaderMenuForeign,
    HeaderMenu,
    HeaderMenuSmall
  },
  methods: {
    showMenu(i) {
      this.isShowMenu = i;
    },
    hideMenu() {
      this.isShowMenu = 0;
    },
    switchShowMenu(i) {
      if (this.isShowMenu == 0) {
        this.isShowMenu = i;
      } else {
        this.isShowMenu = 0;
      }

    }
  },
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 46px;
  background-image: linear-gradient(to bottom, #0e2e6a, #183b80 9%, #1e4080 16%, #112a5d 46%, #061939 84%, #00194a);

  .content {
    @include content;
    position: relative;

    .menu {
      position: absolute;
      line-height: 46px;
      top: 11px;
    }

    .option {
      width: 100%;
      padding: 0 250px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      a {
        color: #7fb2eb;
      }

      @media (max-width: $mobile_width) {
        display: none;
      }

      .itme {
        cursor: pointer;
        margin: 0 25px;

        line-height: 46px;
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #7fb2eb;

        span {
          display: flex;
          align-items: center;
          display: -webkit-flex;
          -webkit-align-items: center;

          .menu_icon {
            height: 20px;
            width: 20px;
          }
        }

        a:hover {
          color: #ffe6cc;
        }
      }

      .itme.up {
        color: #ffe6cc;

        span {
          .menu_icon {
            background-image: url('~@/img/menu_up.svg');
          }
        }
      }

      .itme.down {
        color: #7fb2eb;

        span {
          .menu_icon {
            background-image: url('~@/img/menu_down.svg');
          }
        }
      }
    }
  }
}
</style>