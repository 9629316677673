<template>
  <div>
    <div
      :class="
        `drawballs ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''}`
      "
    >
      <template v-if="lotteryCode != 'lucky7daily'">
        <!--英國天天彩需另外處理-->
        <div
          :class="
            `${lotteryCode} ball ball-${parseInt(item)}     ${
              lastDraw.length >= 10 ? 'moreball' : ''
            }`
          "
          v-for="(item, index) in lastDraw"
          :key="index"
        >
          <span v-if="padLeftLottery.includes(lotteryCode)">
            {{ item | padLeft(2) }}
          </span>
          <span v-else-if="padLeftLottery2.includes(lotteryCode)">
            {{ parseInt(item) }}
          </span>
          <span v-else>
            {{ item }}
          </span>
        </div>
      </template>
      <template v-else>
        <template v-if="reslotteryInfo2.last_period != undefined">
          <!--七顆球代表全開完, 不另做處理-->
          <template
            v-if="reslotteryInfo2.last_period.draw.split(',').length == 7"
          >
            <div
              :class="
                `${lotteryCode} ball ball-${parseInt(item)
                  .toString()
                  .substring(-1)}`
              "
              v-for="(item, index) in reslotteryInfo2.last_period.draw.split(',').slice(0, 6)"
              :key="index"
            >
              <span v-if="padLeftLottery.includes(lotteryCode)">
                {{ item | padLeft(2) }}
              </span>
            </div>
            <div class="add">+</div>
            <div :class="`${lotteryCode} ball ball-${(reslotteryInfo2.last_period.draw.split(',')[reslotteryInfo2.last_period.draw.split(',').length-1])}`">{{reslotteryInfo2.last_period.draw.split(',')[reslotteryInfo2.last_period.draw.split(',').length-1]  | padLeft(2)}}</div>
          </template>
          <!--有球未取完-->
          <template v-else>
            <template
              v-if="reslotteryInfo2.last_period.draw.split(',')[0] == ''"
            >
              <div
                :class="`${lotteryCode} ball ball-99`"
                v-for="(item, index) in 7"
                :key="index + '_4'"
              >
                <span>?</span>
              </div>
            </template>
            <template v-else>
              <!--有多少先畫多少-->
              <div
                :class="
                  `${lotteryCode} ball ball-${parseInt(item)
                    .toString()
                    .substring(-1)}`
                "
                v-for="(item, index) in reslotteryInfo2.last_period.draw.split(
                  ','
                )"
                :key="index + '_2'"
              >
                <span v-if="padLeftLottery.includes(lotteryCode)">
                  {{ item | padLeft(2) }}
                </span>
              </div>
              <div
                :class="`${lotteryCode} ball ball-99`"
                v-for="(item, index) in 7 -
                  reslotteryInfo2.last_period.draw.split(',').length"
                :key="index + '_3'"
              >
                <span>?</span>
              </div>
            </template>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { getLotteryInfo } from '@/api/api.js';
export default {
  props: {
    lotteryCode: {
      type: String,
      required: true,
    },
    lastDraw: Array | [],
  },
  data() {
    return {
      padLeftLottery: [
        'azxy8',
        'jisukuaile',
        'lucky7daily',
        'infinity8',
        'infinity8ex',
        'cannon20',
        'cannon20ex',
        'lucky5',
        'lucky5ex',
        'xglhc',
      ],
      LotteryTwenty: ['cannon20', 'azxy20', 'cannon20ex'],
      padLeftLottery2: ['azxy10', 'azxy20', 'jisusaiche'],
      config: {
        time: '22:00:02',
        duringTime: '22:05:00',
        interval: 1,
        runNow: true,
        timeoutTimer: '',
        countNum: 0,
        continuousCallInterval: '',
      },
      reslotteryInfo2: '',
    };
  },
  methods: {
    getBussTopDataByTimer: async function() {
      if (this.config.runNow) {
        await this.setTimer();
      }

      let nowTime = new Date().getTime();
      let timePoint = this.config.time.split(':').map((i) => parseInt(i));
      let duringTimePoint = this.config.duringTime
        .split(':')
        .map((i) => parseInt(i));

      let recent = new Date().setHours(...timePoint);
      let recentDuringTime = new Date().setHours(...duringTimePoint);

      if (recent > nowTime) {
        //recent時間之前進入, 倒數計時(今天)
        var doRunTime = recent - nowTime;
        this.config.timeoutTimer = setTimeout(this.continuousCall, doRunTime);
      } else if (nowTime < recentDuringTime) {
        //recent 到 recentDuringTime 之間進入
        this.continuousCall();
      } else {
        //recentDuringTime之後時間進入, 倒數計時(隔天)
        if (recent <= nowTime) {
          recent += 24 * 60 * 60 * 1000;
        }
        var doRunTime = recent - nowTime;
        this.config.timeoutTimer = setTimeout(this.continuousCall, doRunTime);
      }
    },
    setTimer: async function() {
      let resBaseInfo = await getLotteryInfo('lucky7daily');
      const matchInfo = Object.keys(resBaseInfo.data).reduce((object, key) => {
        if (!key.match(/^(sgplhc)/)) {
          object[key] = resBaseInfo.data[key];
        }
        return object;
      }, {});
      this.reslotteryInfo2 = matchInfo;
    },
    continuousCall: async function() {
      let self = this;

      this.config.continuousCallInterval = setInterval(function() {
        if (self.config.countNum < 300) {
          self.setTimer();
          self.config.countNum++;
        } else {
          self.config.countNum = 0;
          clearInterval(self.config.continuousCallInterval);

          //打完5分鐘, 計算下一次呼叫涵式時間
          let nowTime = new Date().getTime();
          let timePoint = self.config.time.split(':').map((i) => parseInt(i));
          let recent = new Date().setHours(...timePoint);
          //加上一天
          recent += 24 * 60 * 60 * 1000;
          //剩餘時間
          let doRunTime = recent - nowTime;
          self.config.timeoutTimer = setTimeout(
            self.getBussTopDataByTimer,
            doRunTime
          );
        }
      }, 1000);
    },
  },
  created() {
    let _this = this;
    this.config.getLucky7dailyIntveral = setInterval(function() {
      if (_this.lotteryCode == 'lucky7daily') {
        clearInterval(_this.config.getLucky7dailyIntveral);
        _this.getBussTopDataByTimer();
      }
    }, 500);
  },
  beforeDestroy() {
    clearTimeout(this.config.timeoutTimer);
    clearInterval(this.config.continuousCallInterval);
  },
};
</script>
<style lang="scss" scoped>
.drawballs {
  display: flex;
  justify-content: center;
  .add {
    font-size: 20px;
    color: #646363;
  }
  &.twentyBall {
    display: inline-grid;
    grid-template-columns: repeat(11, 1fr);
    grid-gap: 2px;
    .ball {
      margin-right: 0;
    }
  }
  .ball {
    @include ballSize(28px);
    color: #fff;
    font-size: 17px;
  }
  .ball:nth-child(10) {
    grid-column: span 2;
  }
  .ball {
    grid-column: span 1;
  }
  /*各別彩種設定*/
  .surfing10classic,
  .rolling10,
  .cannon20 {
    &.ball {
      margin-right: 3px;
    }
  }
  .moreball {
    &.ball {
      margin-bottom: 4px;
    }
  }
  .twentyBall:nth-child(10) {
    margin-right: 35px;
  }
  .ball-99 {
    background: #505050;
  }
}
</style>
