<template>
  <div id="app" :class="`${isMobile() ? 'h5' : ''}`">
    <TopBar v-if="!isMobile()"/>
    <Header v-if="!isMobile()"/>
    <H5Top v-else @navChange="navChange($event)"/>
    <SecondLevelAbout v-if="$route.path === '/mobile/about' && showPage === 'about'"
                      @secondLevel="secondLevel($event)"/>
    <SecondLevelDisclaimer v-if="$route.path === '/mobile/about' && showPage === 'disclaimer'"
                           @secondLevel="secondLevel($event)"/>
    <router-view @secondLevel="secondLevel($event)"/>
    <H5Footer v-if="isMobile()" @secondLevel="secondLevel($event)"/>
    <Footer v-if="!isMobile()"/>
    <CopyRight v-if="!isMobile()"/>

  </div>
</template>

<script>
import TopBar from '@/components/common/TopBar.vue'
import Header from '@/components/common/Header.vue'
import Banner from '@/components/common/Banner.vue'
import Footer from '@/components/common/Footer.vue'
import CopyRight from '@/components/common/CopyRight.vue'
import H5Top from '@/components/H5/Top';
import H5Footer from '@/components/H5/Footer';
import SecondLevelAbout from '@/components/H5/SecondLevelAbout';
import SecondLevelDisclaimer from '@/components/H5/SecondLevelDisclaimer';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import {recordUserPageEnter, recordUserPageLeave} from "@/api/api.js";

export default {
  name: 'App',
  components: {
    TopBar,
    Header,
    Banner,
    Footer,
    CopyRight,
    H5Top,
    H5Footer,
    SecondLevelAbout,
    SecondLevelDisclaimer
  },
  data() {
    return {
      hideScrollbar: false,
      showPage: '',
      PVID: 0,
      EnterTime: '',
      OutTime: ''
    }
  },
  methods: {
    isMobile() {
      let check = false;
      let userAgent = window.navigator.userAgent.toLowerCase() || navigator.vendor || window.opera;
      let safari = /safari/.test(userAgent),
          ios = /iphone|ipod|ipad/.test(userAgent);
      if (/windows phone/i.test(userAgent)) {
        return true;
      }
      if (/android/i.test(userAgent)) {
        return true
      }
      if (/android/i.test(userAgent)) {
        return true
      }
      if (ios) {
        if (safari) {
          return true
        }
      }
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    navChange(isNavOpen) {
      this.hideScrollbar = isNavOpen;
    },
    secondLevel(page) {
      this.showPage = page;
    },
    dateFtt(fmt, date) {
      var o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
        "q+": Math.floor((date.getMonth() + 3) / 3),
        "S": date.getMilliseconds()
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substring(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substring(("" + o[k]).length)));
      return fmt;
    },
    getCookie(name) {
      let cookie = {};
      document.cookie.split(';').forEach(function(el) {
        let [k,v] = el.split('=');
        cookie[k.trim()] = v;
      })
      return cookie[name];
  },
   saveCookie(name,value,days) {
    let expires ='';
      if (days) {
        let date = new Date();
        date.setHours('23');
        date.setMinutes('59');
        date.setSeconds('59');
        date.setMilliseconds('999');
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires += ";expires="+date.toUTCString();
      }
     document.cookie = name + "=" + value + expires + "; path=/";
    },
  },

  created() {
    let _url = window.location.hash.replace('#/mobile', '');
    _url = _url.replace('#', '');
    if (this.isMobile()) {
      this.$router.push('/mobile' + _url);
    } else {
      this.$router.push(_url);
    }

    this.$router.afterEach((to, from, failure) => {
      let SiteCode = 'b56e1270-251e-11ed-bb4d-0050568551f7';
      let lotteryHubName;
      if (this.isMobile()) {
        lotteryHubName = {
          GameRule: '游戏规则',
          Sites: '彩票官网',
          About: '我的',
          News: '最新消息',
          Home: '首页'
        }
      } else {
        lotteryHubName = {
          GameRule: '玩法规则',
          Disclaimer: '免责声明',
          AboutUs: '关于我们',
          Home: '首页'
        }
      }
      //leave site
      if (this.PVID != 0) {
        let OutVisitPath = document.location.href.split("#")[0].split("?")[0] + '%23' + from.fullPath;
        let OutPageTitle = from.name;
        if (from.params.name != undefined) {
          OutPageTitle += '-' + lotteryConfig[from.params.name].name
        } else if (lotteryHubName[from.name]) {
          OutPageTitle += '-' + lotteryHubName[from.name];
        }
        this.OutTime = this.dateFtt("yyyy-MM-dd hh:mm:ss", new Date());

        let OutUrl = `/stat/api/VisitStat/out?SiteCode=${SiteCode}&VisitPath=${OutVisitPath}&PageTitle=${OutPageTitle}&EnterTime=${this.EnterTime}&OutTime=${this.OutTime}&PVID=${this.PVID}&IpAddr=`;

        try {
          recordUserPageLeave(SiteCode, OutVisitPath, OutPageTitle, this.EnterTime, this.OutTime, this.PVID)
        } catch (e) {
          console.log('catch1 ', e);
        }
      }


      let VisitPath = document.location.href.split("#")[0].split("?")[0] + '%23' + to.fullPath;
      let PageTitle = to.name;
      if (to.params.name != undefined) {
        PageTitle += '-' + lotteryConfig[to.params.name].name;
      } else if (lotteryHubName[to.name]) {
        PageTitle += '-' + lotteryHubName[to.name];
      }

      this.EnterTime = this.dateFtt("yyyy-MM-dd hh:mm:ss", new Date());

      try {
        recordUserPageEnter(SiteCode, VisitPath, PageTitle, this.EnterTime).then(response => {
          this.PVID = response.Data;
        });
      } catch (e) {
        console.log('catch ', e);
      }
    })
  }
}
</script>

<style scoped lang="scss">

.modal-wrapper{
  width:340px;
  height:177px;
  background:url('~@/img/mobile_popup.png') top center /cover;
  top:138px;
  left:50%;
  transform: translateX(-50%);
  position:fixed;
  z-index:100;
  cursor: pointer;
  @media(min-width: 768px){
    background:url('~@/img/pc_popup.png') top center /cover;
    width: 770px;
    height:400px;
  }
  @media(min-width: 992px){
    top:220px;
  }
}

.backup-domain{
  background:rgba(0,18,54,.8);
  width: 100%;
  top: 50px;
  position: fixed;
  z-index:100;
  display:flex;
  font-size:14px;
  justify-content: center;
  padding:12px 10px;
  font-weight:900;
  gap:36px;
  .domain-column{
    text-align:center;
    color:#ffea8e;
    flex-direction:column;
    flex-wrap:wrap;
    display:flex;
    justify-content: center;
    background:rgba(#939393, .2);
    border-radius:10px;
    padding: 11px 15px;
  }
  a{
    color:#fff;
    font-weight:400;
    font-style:italic;
    display:block;
    margin:5px auto 0;
    text-decoration: underline;
  }

  @media(min-width: 992px){
    top: 100px;
    width:520px;
    left:50%;
    transform: translateX(-50%);
    border-radius: 10px;
    border: 1px solid rgba(#3f4e7b,.8);
    font-size:18px;
    gap:40px;
    justify-content: center;
    padding:10px 16px;
    .domain-column{
      width:200px;
      padding:7px 15px;
    }
    a{margin-top:10px;}
  }
}

.icons-cancel {
  position:absolute;right:5px;top:5px;
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  opacity:0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAsMCwyNTYsMjU2IgpzdHlsZT0iZmlsbDojMDAwMDAwOyI+CjxnIGZpbGw9IiNmZmZmZmYiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxnIHRyYW5zZm9ybT0ic2NhbGUoMTAuNjY2NjcsMTAuNjY2NjcpIj48cGF0aCB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC45NzA1NiwxMi4wMDAzKSByb3RhdGUoLTQ1LjAwMSkiIGQ9Ik0xMSwwLjdoMnYyMi42aC0yeiI+PC9wYXRoPjxwYXRoIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00Ljk3MDU2LDEyLjAwMDMpIHJvdGF0ZSgtNDUuMDAxKSIgZD0iTTAuNywxMWgyMi42djJoLTIyLjZ6Ij48L3BhdGg+PC9nPjwvZz4KPC9zdmc+') 50% 50% no-repeat;
  background-size: 80%;
  @media (min-width: 768px) {
    top:15px;
    right:15px;
    width:40px;
    height:40px;
  }
}

.sticky{
  position:fixed;
  bottom: 10px;
  right:30px;
  width:74px;
  height:74px;
  background:url('~@/img/sticky.png') top center /cover;
  display:none;
  cursor: pointer;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.5);
  border-radius: 10px;
  z-index:99;
  @media(min-width:992px){
    display:block;
  }
}


</style>
