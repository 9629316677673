<template>
    <div class="draw_number">
        <template v-if="lotteryCode !='lucky7daily'"><!--英國天天除外-->
            <div v-for="(item,index) in lastDraw"
                :key="index"
                :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`">
                <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(item)}}</span>
                <span v-else>{{item}}</span>
            </div>
        </template>
        <template v-else>
            <template v-if="lastDraw[0] == ''">
                <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 7" :key="index+'_3'">
                    <span> ? </span>
                </div>
            </template>
            <template v-else>
                <div v-for="(item,index) in lastDraw.slice(0, 6)"
                    :key="index"
                    :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`">
                    <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                </div>
              <div class="add" v-if="padLeftLottery.includes(lotteryCode)">+</div>
              <div :class="`${lotteryCode} ball ball-${getIntNumStyle(lastDraw[lastDraw.length-1])}`">
                <span v-if="padLeftLottery.includes(lotteryCode)">{{lastDraw[lastDraw.length-1]  | padLeft(2)}}</span>
              </div>

              <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 7- lastDraw.length" :key="index+'_2'">
                  <span> ? </span>
              </div>
            </template>
        </template>
    </div>
</template>
<script>
export default {
    props:{
        lastDraw:Array,
        lotteryCode:''
    },
    components:{
    
    },
  data(){
    return{
      padLeftLottery: ['azxy8','jisukuaile','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc'],
      padLeftLottery2: ['azxy10','azxy20','jisusaiche']
    }
  },
    methods: {
        getIntNumStyle(num){
            return parseInt(num)
        }
    },
}
</script>
<style lang="scss" scoped>
.draw_number{
    .ball {
        margin: 0 4px 5px;
        text-align: center;
      color:#fff;
    }
  .add{
    font-size:20px;
    width:12px;
    height:45px;
    line-height: 45px;
    margin:0 20px;
  }
}
.ball-99{
    background: #505050
}
 


</style>
