<template>
  <div class="Menu">
    <div class="menu_content" v-for="(item,index) in lotteryList" :key="index">
      <div class="title">
        {{item.name}}
      </div>
      <div class="row">

        <div class="menu_item" v-for="(_item,_index) in item.subItem" :key="_index">
          <a :href="`${_item.url}`" @click="closeMenu">
            {{_item.name}}
          </a>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {lotteryConfig} from "@/assets/lotteryConfig.js";
export default {
  data(){
    return{
      lotteryList:[
        {
          name: "境外彩",
          subItem:[
            {
              name: "幸运五星彩",
              url: "/#/mobile/lottery/penta5classic",
            },
            {
              name: "英国飞艇",
              url: "/#/mobile/lottery/surfing10classic",
            },
            {
              name: "英国天天彩",
              url: "/#/mobile/lottery/lucky7daily",
            },
            {
              name: "英国五球彩",
              url: "/#/mobile/lottery/pl5daily",
            },
            {
              name: "英国赛车",
              url: "/#/mobile/lottery/rolling10",
            },
            {
              name: "英国时时彩",
              url: "/#/mobile/lottery/penta5",
            },
            {
              name: "英国快乐彩",
              url: "/#/mobile/lottery/infinity8",
            },
            {
              name: "英国快乐8",
              url: "/#/mobile/lottery/cannon20",
            },
            {
              name: "英国11选5",
              url: "/#/mobile/lottery/lucky5",
            },
            {
              name: "英国快三",
              url: "/#/mobile/lottery/gamma3",
            },
            {
              name: "英国极速赛车",
              url: "/#/mobile/lottery/rolling10ex",
            },
            {
              name: "英国极速时时彩",
              url: "/#/mobile/lottery/penta5ex",
            },
            {
              name: "英国极速快乐彩",
              url: "/#/mobile/lottery/infinity8ex",
            },
            {
              name: "英国极速快乐8",
              url: "/#/mobile/lottery/cannon20ex",
            },
            {
              name: "英国极速11选5",
              url: "/#/mobile/lottery/lucky5ex",
            },
            {
              name: "英国极速快三",
              url: "/#/mobile/lottery/gamma3ex",
            },
            {
              name: "澳洲幸运5",
              url: "/#/mobile/lottery/azxy5",
            },
            {
              name: "澳洲幸运8",
              url: "/#/mobile/lottery/azxy8",
            },
            {
              name: "澳洲幸运10",
              url: "/#/mobile/lottery/azxy10",
            },
            {
              name: "澳洲幸运20",
              url: "/#/mobile/lottery/azxy20",
            },
            {
              name: "极速快三",
              url: "/#/mobile/lottery/jisukuai3",
            },
            {
              name: "极速赛车",
              url: "/#/mobile/lottery/jisusaiche",
            },
            {
              name: "极速时时彩",
              url: "/#/mobile/lottery/jisushishi",
            },
            {
              name: "极速快乐十分",
              url: "/#/mobile/lottery/jisukuaile",
            }

          ]
        },
        {
          name: "全国彩",
          subItem:[
            {
              name: "七星彩",
              url: "/#/mobile/lottery/qxc",
            },
            {
              name: "排列三",
              url: "/#/mobile/lottery/pl3",
            },
            {
              name: "排列五",
              url: "/#/mobile/lottery/pl5",
            },
            {
              name: "香港六合彩",
              url: "/#/mobile/lottery/xglhc",
            },
          ]

        },
      ],

    }

  },
   methods:{
        closeMenu(){
            this.$parent.navOpen=false;

        }
    }
}
</script>
<style lang="scss" scoped>
.Menu{
  width: 100%;
  height:calc(100vh - 50px);
  position:fixed;
  z-index:3;
  background-color: #f6f5f5;
  padding-bottom: 20%;
  overflow-y:auto;
  .menu_content{

    .title{
      font-size: 16px;
      font-weight: bold;
      color: #727272;
      height: 36px;
      line-height: 36px;
      background-color: #fff;
      text-align: center;
    }
    .row{
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 10px;
      padding: 20px 15px;
    }

    .menu_item{
      border-radius: 5px;
      border: 1px solid #cfcfcf;
      background-color: #fff;
      width: 100%;
      height: 40px;
      margin: 0 auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      -webkit-align-items: center;
      a{
        color: #434343;
        font-size: 12px;
        -webkit-box-flex-grow: 1;
        -moz-box-flex-grow: 1;
        -webkit-flex-grow: 1;
            -ms-flex-grow: 1;
                flex-grow: 1;
                    text-align: center;
       
      }
    }
  }

}
</style>