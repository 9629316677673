<template>
    <div class="home">
        <Banner />

        <!--計時器那塊-->
        <div class="main_info">
          <div class='backup-domain'>
            <div class='domain-column'>
              备用域名1
              <a href='https://bwfw1881.net'>
                bwfw1881.net
              </a>
            </div>
            <div class='domain-column'>
              备用域名2
              <a href='https://bwfw1882.net'>
                bwfw1882.net
              </a>
            </div>
          </div>
<div class='info-content'>
  <ComingLottery />
  <div class="hot_lottery">
    <h2>
      热门彩种
    </h2>
    <div class="content">
      <a v-for="(item, index) in displayHotLottery" :key="'hot' + index" class="item grey-card" :href="`/#/lottery/${item}`">
        <img class="icon" :src="require('@/img/ballIcon/'+ homeLotteryConfig[item].imgUrl)">
        <div class="info" v-if="baseInfo[item]">
          <img src="@/img/hot.png" style="width:36px;height:14px;margin-bottom:5px;">
          <div class="info_title">
            {{homeLotteryConfig[item].name}}
          </div>
          <div class="draw_period">
            {{baseInfo[item].last_period.period_no | periodSuffix(item, baseInfo[item].last_period.draw_time, baseInfo[item].last_period.period_no)}}
          </div>
        </div>
      </a>
    </div>

  </div>
  <div class='country_lottery'>
    <h2>
      国别分类
    </h2>
    <div class="lottery_type">
      <div class="item china" @click="changeHotLottery(1)">
      </div>
      <div class="item uk"  @click="changeHotLottery(2)">
      </div>
      <div class="item australia" @click="changeHotLottery(3)">

      </div>
      <div class="item" style="background:#f3f3f3;box-shadow: none;"></div>
      <div class="item" style="background:#f3f3f3;box-shadow: none;"></div>
      <div class="item" style="background:#f3f3f3;box-shadow: none;"></div>
    </div>
  </div>
</div>

        </div>
        <!--display lottery那塊-->
        <div class="display_lottery">
            <div class="content">
                <LotteryCardType2 :imgUrl="homeLotteryConfig[key].imgUrl" :lotteryCode="`${key}`"  v-for="(item, key, index) in homeLotteryConfig" :key="key" v-if="readMoreCount-1 >= index"  @openVedio="openVedio"/>
            </div>
            <div class="read_more_row" v-if="readMoreCount >= 4  && readMoreCount <= Object.keys(homeLotteryConfig).length">
                <div class="read_more" @click="addReadMoreCount">
                    加载更多
                </div>
            </div>
        </div>
        
        <div v-if="showVedio">
            <pcVideoLive @closeVedio="closeVedio" :lotteryType="clickVedioLotteryType" :lotteryInfo="clickVedioLotteryinfo"></pcVideoLive>
        </div>
        <!--新聞-->
        <NewsCollection />
    </div>
</template>

<script>
    // @ is an alias to /src
    import ComingLottery from '@/components/common/ComingLottery.vue'
    import LotteryCardType2 from '@/components/common/lotteryCard/lotteryCard_type2.vue'
    import NewsCollection from '@/components/common/NewsCollection.vue'
    import { mapState, mapGetters, mapActions } from 'vuex';
    import {lotteryConfig} from "@/assets/lotteryConfig.js";
    import Banner from '@/components/common/Banner.vue'
    import pcVideoLive from "@/components/live_stream/pcVideoLive.vue";
    
    export default {
        name: 'Home',
        components: {
            ComingLottery,
            LotteryCardType2,
            NewsCollection,
            Banner,
            pcVideoLive,
        },
        data() {
            return {
                lotteryTypeSetting:  [
                    ["qxc", "xglhc", "pl3", "pl5"],
                    ["qxc", "xglhc", "pl3", "pl5"],
                    ["penta5", "infinity8", "cannon20", "lucky5", "lucky7daily", "pl5daily"],
                    ["azxy5", "azxy10", "azxy20", "azxy8"],

                ],
                displayHotLottery: [
                    "qxc", "xglhc", "pl3", "pl5"
                ],
                readMoreCount: 4,
                homeLotteryConfig: lotteryConfig,
                currentHistoryName: '',
                showVedio: false,
                clickVedioLotteryType:'',
                clickVedioLotteryinfo:'',
            }
        },
        computed: {
            ...mapState([
                "baseInfo"
            ]),
            
        },
         watch: {
            '$route': {
                handler: function(to, from) {
                this.currentHistoryName = to.params.name;
                this.lotteryUtil.changeLottery();
                },
                immediate: true
            } 
        },
        methods: {
            changeHotLottery (i) {
                this.displayHotLottery = this.lotteryTypeSetting[i];
            },
            addReadMoreCount () {
                this.readMoreCount+=4;
            },
            openVedio(lotteryCode, lotteryInfo){
                this.clickVedioLotteryType = lotteryCode;
                this.clickVedioLotteryinfo = lotteryInfo;
                this.showVedio = true;
            },
            closeVedio(){
                this.showVedio = false;
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    .main_info {
      position:relative;
        width: 1280px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        margin: auto;
        overflow:hidden;
        margin-top: -68px;
        display: flex;
        flex-wrap:wrap;
        justify-content: center;

        .hot_lottery {
            width: 490px;
            height: 100%;

            .content {
                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;

                .item {
                    width: 240px;
                    height: 92px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    padding: 11px 20px;
                    background: #f3f3f3;
                    border-radius:10px;

                    &:nth-child(odd){
                        margin-right: 10px;
                    }

                    .icon {
                        width: 60px;
                        height: 60px;
                        margin-right:10px;
                    }

                    .info {
                        margin-left: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .draw_period {
                            font-size: 12px;
                            text-align: left;
                            color: #a1a1a1;
                        }
                    }
                }
            }
        }

        .lottery_type {
          flex: 0;
            margin-top:32px;
            width: 366px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
                width: 119px;
                height: 140px;
                object-fit: contain;
                border-radius: 4px;
                box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
                background-size: cover;
                text-align: center;
                cursor: pointer;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
                font-size: 20px;
                font-weight: bold;
                color: #0f2957;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom:30px;
            }
            .china{
                background-image: url('~@/img/home_lottery_type1.png');
            }
            .australia{
                background-image: url('~@/img/home_lottery_type2.png');
            }
            .uk{
                background-image: url('~@/img/home_lottery_type6.png');
            }
            .disable{
              background-color: rgba(255, 255, 255, 0.70);
              background-blend-mode: soft-light;
            }
        }

    }
    .info-content{
      display:flex;
      justify-content:center;
      gap:10px;
      margin-top:15px;
    }
    .display_lottery {
        margin-top: 50px;
        width: 100%;
        .read_more_row{
            margin-top: 40px;
            margin-bottom: 90px;
            .read_more{
                width: 138px;
                height: 42px;
                border-radius: 21px;
                background-color: #001236;
                font-size: 17px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 44px;
                letter-spacing: normal;
                text-align: center;
                color: #ffe6cc;
                margin: auto;
                cursor: pointer;
            }
        }

        .content {
            @include content();
            padding: 0 0 50px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    h2{
        font-size: 20px;
        text-align: left;
        font-weight: bolder;
        color: #001236;
        border-bottom: 0.5px #aab2ba solid;
        height: 50px;
        line-height: 39px;
    }

    .info_title{
      margin-bottom: 10px;
      font-size: 18px;
      text-align: left;
      color: #59626c;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .backup-domain{
      background:#001336;
      color:#7ab9ff;
      display:flex;
      justify-content: center;
      height:34px;
      align-items: center;
      font-style: italic;
      font-weight:bold;
      flex-basis: 100%;
      gap:23px;
      font-size:18px;
      .domain-column{
        position:relative;
        padding-right:26px;
        &:first-child:after{
          position:absolute;
          content:'';
          display:block;
          top:-4px;
          width:1px;
          height:26px;
          background:#7D8E9B;
          right:0;
          z-index:1;
        }
      }
      a{color:#fff;text-decoration: underline;font-weight:normal;margin-left:20px;}
    }
</style>